import React from "react";
import {
  Stack,
  Text,
  Center,
  TabPanels,
  TabList,
  Tab,
  TabPanel,
  Tabs,
  TabIndicator,
  Box,
  Button,
  Image,
  Flex,
  Heading,
  HStack,
  Container,
} from "@chakra-ui/react";
import "@fontsource/lora";
import "@fontsource/lora/400-italic.css";
import { Link } from "gatsby";

import Partners from "./Partners";
import ArticleList from "../articleList/ArticleList";
import Species from "./Species";
import AboutSection from "../../content/AboutSection";
import AuthorsSection from "../../content/AuthorsSection";
import ReviewerSection from "../../content/ReviewerSection";
import ContactSection from "../../content/ContactSection";
import NewsList from "../news/NewsList";

const MainPage = (props) => {
  const {
    recent,
    mostCited,
    popular,
    refs,
    speciesOptions,
    news,
    categories,
    popularTab,
    savePopularTab,
  } = props;

  const {
    popularArticlesRef,
    recentArticlesRef,
    aboutRef,
    authorsRef,
    reviewersRef,
    contactRef,
    speciesRef,
    partnersRef,
    newsRef,
  } = refs;

  return (
    <Stack justifyContent="center">
      <Center marginTop="100px">
        <Stack direction="horizontal">
          <Text fontFamily="Lora" color="#26428b" fontSize="9xl">
            &micro;P
          </Text>
          <Stack>
            <Center>
              <Text
                fontFamily="Lora"
                color="#26428b"
                textShadow="1px 2px 2px"
                fontSize="7xl"
              >
                microPublication Biology
              </Text>
            </Center>
            <Center>
              <Text
                fontFamily="Lora"
                fontStyle="italic"
                color="#26428b"
                textShadow="1px 2px 2px"
                fontSize="5xl"
              >
                get your data out, be cited
              </Text>
            </Center>
          </Stack>
        </Stack>
      </Center>

      <Center>
        <Container maxWidth="7xl">
          <Flex justify="space-between">
            <Box
              maxWidth="md"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              backgroundColor="white"
              padding="1em"
            >
              <Heading size="md" color="gray">
                Starting January 1, 2025, the article processing charge (APC)
                for submissions received on or after this date will increase
                from $250 to $350.
              </Heading>
            </Box>
            <Link to="/journals/biology/categories/ecology and evolution">
              <HStack
                maxWidth="md"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                _hover={{ border: "1px solid" }}
                backgroundColor="white"
                padding="1em"
              >
                <Image
                  borderRadius="full"
                  boxSize="100px"
                  src="/static/images/eeb-logo.png"
                  alt="Ecology and Evolution"
                />
                <Heading size="md" color="gray">
                  Now publishing Ecology and Evolution papers
                </Heading>
              </HStack>
            </Link>
          </Flex>
        </Container>
      </Center>

      <ArticleList
        articles={recent}
        label="Recent microPublications"
        loadMoreText="Load more microPublications..."
        articlesRef={recentArticlesRef}
        page={6}
      />

      <Center ref={popularArticlesRef}>
        <Tabs
          position="relative"
          variant="unstyled"
          index={popularTab}
          onChange={(index) => savePopularTab(index)}
        >
          <TabList maxW="7xl">
            <Tab fontSize="xl">PubMed Central Views</Tab>
            <Tab fontSize="xl">Citations</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <ArticleList
                articles={popular}
                label="Popular microPublications by PubMed Central Views"
                loadMoreText="Load more microPublications..."
                page={6}
              />
            </TabPanel>
            <TabPanel>
              <ArticleList
                articles={mostCited}
                label="Most Cited microPublications"
                loadMoreText="Load more microPublications..."
                page={6}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Center>

      <Species speciesRef={speciesRef} speciesOptions={speciesOptions} />
      <NewsList
        news={news.filter((n) => n.publish)}
        newsRef={newsRef}
        page={6}
      />

      <AboutSection aboutRef={aboutRef} />
      <AuthorsSection authorsRef={authorsRef} categories={categories} />
      <ReviewerSection reviewersRef={reviewersRef} />
      <Partners partnersRef={partnersRef} />
      <ContactSection contactRef={contactRef} />
    </Stack>
  );
};

export default MainPage;
