import React from "react";

import Section from "../ui/common/Section";
import Paragraph from "../ui/common/Paragraph";
import { Link } from "gatsby";

const items = [
  {
    title: "Are You Charging Article Processing Charges (APC)?",
    text: (
      <Paragraph>
        From the beginning <i>microPublication Biology</i> has been supported by
        a grant from the National Libraries of Medicine. However, as of April
        2nd 2022, as part of our long-term sustainability plan we instituted an
        article processing charges (APCs) of $250 per article. Starting January
        1, 2025, the article processing charge (APC) for submissions received on
        or after this date will increase from $250 to $350. This charge should
        cover costs but not new initiatives. No article will be refused because
        of an author's inability to pay page these charges.
      </Paragraph>
    ),
  },
  {
    title: "How Do You Differ From Traditional Journals?",
    text: (
      <>
        <Paragraph>
          The major goal of <i>microPublication Biology</i> is to rapidly place
          research findings into the public domain. Thus unlike other journal
          platforms, we publish single high quality research results,
          independent of perceived impact, which can be new research findings,
          negative results, reproduced/replicated results or “unpublished
          observations” from prior publications. Single results stand alone, and
          do not require a narrative story to placate editors. Placing such
          findings into the public domain not only advances the scientific
          endeavor, but also gives credit to the individual(s) that did the
          work.
        </Paragraph>

        <Paragraph>
          Importantly, unlike other journal platforms, information from each
          microPublication is directly incorporated into community databases
          (e.g., WormBase) through the use of author populated user-friendly web
          forms that rely on controlled vocabularies, when available – thus
          advancing the goal of making the content of each microPublication
          computable.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Are microPublications Findable on PubMed?",
    text: (
      <Paragraph>
        Yes! microPublications are indexed in PubMed Central and are findable in
        both PubMed Central and PubMed. We are also indexed in EuropePMC.
      </Paragraph>
    ),
  },
  {
    title: "Can I Submit My Work to microPublication and bioRxiv?",
    text: (
      <Paragraph>
        Yes, you can submit your article to microPublication and deposit it in
        bioRxiv.
      </Paragraph>
    ),
  },
  {
    title: "Where Can I Sign up to Be a Reviewer for microPublication Biology?",
    text: (
      <Paragraph>
        On the{" "}
        <Link to="/about/for-reviewers#become-a-reviewer">
          <u>Guidelines for reviewers</u>
        </Link>{" "}
        page you can find information on how to sign up to be on the reviewer’s
        list.
      </Paragraph>
    ),
  },

  {
    title: "Who Can Be a Reviewer for microPublication Biology?",
    text: (
      <Paragraph>
        Principal Investigators, Postdocs, and senior graduate students can
        review articles for microPublication Biology. Before selecting a postdoc
        or a senior graduate student as reviewer, we ask their supervisor’s
        approval.
      </Paragraph>
    ),
  },
  {
    title: "What Is the Career Value of a microPublication?",
    text: (
      <Paragraph>
        First off, at this point, you would be crazy to rely solely on
        microPublications for promotion. We just don't know yet how academic
        scientists will respond. If the data won’t be published,
        microPublications are clearly better than nothing. Digging deeper, let's
        consider co-authorships. What is the CV value of a sixth authorship?
        Certainly worth something, especially if augmented by first-authorships.
        What did you do to deserve that co-authorship? Probably it was to
        contribute a figure or a panel in a complex figure, or a supplemental
        figure. Well, a microPublication is essentially one figure, so we
        consider it of equal value, and possibly more valuable because you are
        the first author. Lastly, some microPublications are of high value and
        will be relatively highly cited. As you can see, microPublications have
        clear value to your CV already, but perhaps more importantly, to the
        broader scientific enterprise.
      </Paragraph>
    ),
  },
  {
    title:
      "Can I Mention ‘Unpublished Data’ or ‘Data Not Shown’ in My microPublication?",
    text: (
      <>
        <Paragraph>
          microPublication Biology aims at getting all data to the community,
          hence we discourage mention of ‘unpublished’ and ‘data not shown’
          observations in the manuscript.
        </Paragraph>
        <Paragraph>
          Data not shown and unpublished observations should be shown in the
          microPublication or microPublished as a separate submission.
        </Paragraph>
      </>
    ),
  },
  {
    title:
      "Can I Reuse Data Published in microPublication Biology in Other Articles I Write?",
    text: (
      <Paragraph>
        Yes, you can cite any data published in microPublication Biology as you
        would cite any other data published in a previously published research
        article.
      </Paragraph>
    ),
  },
  {
    title: "Can I Submit Negative Results?",
    text: (
      <Paragraph>
        Yes, microPublication Biology accepts negative results.
      </Paragraph>
    ),
  },
  {
    title: "What are Integrations?",
    text: (
      <Paragraph>
        These articles are extended narratives for data published as a
        microPublication. Each <b>Integrations</b> article furthers the
        discussion of data reported in multiple microPublications. Integrations
        can include a final experiment that ties published microPublication data
        into a cohesive report. Authors submitting Integrations should follow
        all Author Guidelines as stated for normal microPublications.
      </Paragraph>
    ),
  },
];

const FaqsSection = (props) => {
  const { faqsRef, isPage } = props;

  return (
    <Section
      items={items}
      title="Frequently Asked Questions"
      sectionRef={faqsRef}
      isPage={isPage}
    />
  );
};

export default FaqsSection;
