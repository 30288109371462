import React, { useState } from "react";
import {
  Avatar,
  Button,
  Box,
  Heading,
  Container,
  Center,
  Wrap,
  WrapItem,
  Text,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  VStack,
  useDisclosure,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Badge,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { groupBy, capitalize } from "lodash";

const Species = (props) => {
  const { breadcrumb, speciesRef, speciesOptions } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [speciesMatches, setSpeciesMatches] = useState([]);

  const groupedSpecies = groupBy(speciesOptions, "division");

  const divisions = Object.keys(groupedSpecies).sort(
    (a, b) => groupedSpecies[b].length - groupedSpecies[a].length
  );
  // divisions.push(divisions.shift());

  const handleSearch = (e) => {
    onOpen();
    const { value } = e.target;

    setSpeciesMatches(
      groupBy(
        speciesOptions.filter(
          (s) =>
            s.value.toLowerCase().includes(value.toLowerCase()) ||
            s.label.toLowerCase().includes(value.toLowerCase())
        ),
        "division"
      )
    );
  };

  return (
    <Center>
      <Container
        maxW="7xl"
        borderWidth="1px"
        borderRadius="lg"
        p="10vh"
        ref={speciesRef}
      >
        {breadcrumb && (
          <Breadcrumb>
            {breadcrumb.map((bc) => (
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to={bc[1]}>
                  {bc[0]}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Species</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
        <HStack paddingBottom="2">
          <Heading size="lg" color="gray">
            <Link to="/journals/biology/species">Species</Link>
          </Heading>
          <Text>Click to view the respective corpus</Text>

          <Popover autoFocus={false} isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
              <Input
                placeholder="Search species..."
                id="speciesSearch"
                onChange={handleSearch}
                onBlur={onClose}
                onClick={onOpen}
                width="300px"
                // value={speciesTerm}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />

              <PopoverBody>
                {divisions.map(
                  (d) =>
                    speciesMatches[d] && (
                      <VStack>
                        <Badge marginTop="5px">
                          {d === "undefined" ? "Other" : d}
                        </Badge>
                        {speciesMatches[d].map((s) => (
                          <Link
                            to={`/journals/biology/species/${s.value
                              .replace(".", "")
                              .replace(" ", "-")}`}
                          >
                            <Box as={Button} variant="outline" size="lg">
                              <Avatar
                                size="sm"
                                name={s.label}
                                src={
                                  s.imageSrc &&
                                  `/static/images/species-small/${s.imageSrc}`
                                }
                              />
                              <Text
                                paddingLeft="1"
                                paddingRight="1"
                                fontSize="sm"
                              >
                                {s.label}
                              </Text>
                              <Tag size="sm" borderRadius="full">
                                <TagLabel>{s.articleCount}</TagLabel>
                              </Tag>
                            </Box>
                          </Link>
                        ))}
                      </VStack>
                    )
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
        <Wrap>
          {divisions.map((division) => (
            <WrapItem>
              <VStack
                borderWidth="1px"
                borderRadius="lg"
                p="5px"
                boxShadow="md"
              >
                <Text>
                  {division === "undefined" ? "Other" : capitalize(division)}
                </Text>
                <Wrap>
                  {groupedSpecies[division].map((species) => (
                    <WrapItem>
                      <Link
                        to={`/journals/biology/species/${species.value
                          .replace(".", "")
                          .replace(" ", "-")}`}
                      >
                        <Box as={Button} variant="outline" size="md" backgroundColor={species.articleCount > 5 ? "lightblue" : "white"}>
                          <Avatar
                            size="sm"
                            name={species.label}
                            src={
                              species.imageSrc &&
                              `/static/images/species-small/${species.imageSrc}`
                            }
                          />
                          <Text
                            paddingLeft="1"
                            paddingRight="1"
                            fontSize="sm"
                            as={species.articleCount > 5 ? "b" : ""}
                          >
                            {species.label}
                          </Text>
                          <Tag size="sm" borderRadius="full">
                            <TagLabel>{species.articleCount}</TagLabel>
                          </Tag>
                        </Box>
                      </Link>
                    </WrapItem>
                  ))}
                </Wrap>
              </VStack>
            </WrapItem>
          ))}
        </Wrap>
      </Container>
    </Center>
  );
};

export default Species;
